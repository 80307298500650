// Entry point for the build script in your package.json

// Window animation
import WindowAnimation from "./lib/WindowAnimation";
WindowAnimation.start();
document.addEventListener("layoutchanged", function () {
  WindowAnimation.queueLayout();
});

// Collision detection
import CollisionDetector from "./lib/CollisionDetector";
CollisionDetector.start();

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// Rails
import { start as startRails } from "@rails/ujs";
startRails();

// Grid overlay
import gridOverlay from "./lib/gridOverlay";
gridOverlay();

// Stimulus
import "@stimulus/polyfills";
import { Application } from "stimulus";
import CollisionDetectController from "./controllers/CollisionDetectController";
const application = Application.start();
application.register("collision-detect", CollisionDetectController);

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;
